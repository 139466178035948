@import "bootstrap/dist/css/bootstrap.min.css";

body {
  width: 100vw;
  overflow-x: hidden !important;
}

.navbar {
  padding-top: 30px;
  padding-bottom: 30px;
}

.navbar-nav .nav-link {
  color: #000;
  font-size: 0.9rem;
  font-weight: 500;
  margin-right: 10px;
}

#hero-section {
  background-image: url("./assets/img/bg_hero.png");
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}

#solutions-section,
#vision-section {
  background-color: #ffffff;
}

#mission-section {
  background-color: #f7f2fc;
}

#testimonials-section,
#service-count-section {
  background-color: #f7f7f9;
}

#bottom-hero-section {
  background: url("./assets/img/bg_cta.png"),
    linear-gradient(180deg, #d600c9 0%, #6300cf 100%);
  background-repeat: no-repeat;
  background-size: cover;
}

footer ul.list-unstyled > li > a {
  color: #fff;
  text-decoration: none;
}

footer ul.list-unstyled > li {
  margin-bottom: 5px;
  font-size: 0.9rem;
}

.card {
  border: unset;
}

.text-primary {
  background: linear-gradient(90deg, #d600c9 0.98%, #6300cf 84.7%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.btn-primary {
  background: linear-gradient(180deg, #d600c9 0%, #6300cf 100%);
  border: unset;
}

.btn-light {
  color: #6300cf;
  font-weight: 500;
}

.form-control,
.form-select {
  background-color: #f2f2f2;
  border: unset;
  padding: 15px 20px;
}

.badge {
  padding: 8px 16px;
}

.bg-light-yellow {
  background-color: #f5a30233 !important;
  color: #000;
}

.bg-light-green {
  background-color: #1dd20033 !important;
  color: #000;
}

.bg-light-red {
  background-color: #ff000033 !important;
  color: #000;
}

.bg-light-orange {
  background-color: #fb5c0333 !important;
  color: #000;
}

.bg-light-purple {
  background-color: #d600c933 !important;
  color: #000;
}

.form-label {
  font-weight: bold;
}

.py-5 {
  padding-top: 7.5rem !important;
  padding-bottom: 7.5rem !important;
}

.mt-5 {
  margin-top: 7.5rem !important;
}

.pb-5 {
  padding-bottom: 7.5rem !important;
}

.min-vh-85 {
  min-height: 85vh;
}

.navbar-toggler {
  border: unset;
}
.navbar-toggler:focus {
  box-shadow: unset;
}

#hero-section .btn {
  margin-top: 5rem;
}

p {
  font-size: 1.25rem;
}

h3 {
  font-size: 2.5rem;
  font-weight: bold;
}

h4 {
  font-size: 2rem;
}

h5 {
  font-size: 1.5rem;
  font-weight: bold;
}

.clientele-logo {
  width: 320px !important;
  object-fit: contain;
  object-position: center;
}

.clientele-logo1 {
  width: 300px;
  height: 90px !important;
  margin-top: 23px;
  object-fit: contain;
  object-position: center;
}

.clientele-logo2 {
  width: 200px;
  height: 90px !important;
  object-fit: cover;
  object-position: center;
}

@media only screen and (max-width: 992px) {
  .footer-socials {
    width: 30px;
    height: 30px;
  }
}

@media only screen and (max-width: 567px) {
  .Footer-links {
    border-bottom: 1px solid rgba(238, 236, 236, 0.322);
    margin-bottom: 10px;
  }
}

@media only screen and (max-width: 449px) {
  .clientele-logo1 {
    width: 250px;
    height: 70px !important;
    margin-top: 30px;
    object-fit: contain;
    object-position: center;
  }

  .clientele-logo2 {
    width: 200px;
    height: 90px !important;
    object-fit: cover;
    object-position: center;
  }
}

@media only screen and (max-width: 342px) {
  .clientele-logo1 {
    width: 250px;
    height: 70px !important;
    margin-top: 26px;
    object-fit: contain;
    object-position: center;
  }

  .clientele-logo2 {
    width: 200px;
    height: 90px !important;
    object-fit: cover;
    object-position: center;
  }
}
